
import axios from 'axios';
import coreAPI from "../services/api/core-api.service";

const uploadCSV = async (file,URL) => {
  try {
    // Convert the CSV file to BASE64
    const toBase64 = (file) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]); //  base64 encoded value of the file
      reader.onerror = (error) => reject(error);
    });

    const base64File = await toBase64(file);

    // Prepare the request body
    const body = {
      fileName:file.name,
      fileContent: base64File,
      isBase64Encoded: true,

    };
    // Set the headers
    const headers = {
      // 'origin': process.env.REACT_APP_OAUTH_REDIRECT_URL
      // 'file-name': file.name,
    };
    console.log("body",body)
    console.log("headers",headers)
    const cors_url = `https://cors-anywhere.herokuapp.com/${URL}`

    // Make the Axios POST request
    // const response = await axios.post(URL, body, {
    //   headers: headers,
    // });
    const path = `iata/uploadfile`
    const response = await coreAPI().post(
      path,
      body
    );

    console.log('File uploaded successfully:', response);
    return response
  } catch (error) {
    console.error('Error uploading file:', error);
    return {
        status : 400,
        data : 'Error uploading file'
    }
  }
};

export default uploadCSV;
