// src/CsvPreview.js
import React, { useState, useMemo } from "react";
import { Table, Row, Col, Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";

const CsvPreview = ({ headers, data }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [showErrorsOnly, setShowErrorsOnly] = useState(false);
  const itemsPerPage = 20;

  const filteredData = useMemo(() => {
    let filtered = data;
    if (searchTerm) {
      filtered = filtered.filter((row) =>
        Object.values(row).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
    if (showErrorsOnly) {
      filtered = filtered.filter((row) => row.errorText);
    }
    return filtered;
  }, [data, searchTerm, showErrorsOnly]);

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const getSerialNumber = (index) => currentPage * itemsPerPage + index + 1;

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const currentItems = filteredData.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <Row className="justify-content-md-center">
      <h3 className="text-center flex-grow-1">Preview</h3>

      <Row className="mt-4">
        <Col md={6}>
        <Form.Check
            type="checkbox"
            id="show-errors-only"
            label="Show Errors Only"
            className="d-flex align-items-center"
          >
            <Form.Check.Input
              type="checkbox"
              onChange={(e) => {
                setShowErrorsOnly(e.target.checked);
                setCurrentPage(0);
              }}
              style={{
                border: "2px solid black",
                padding: "5px",
                borderRadius: "5px",
                marginRight: "10px",
              }}
            />
            <Form.Check.Label style={{ marginLeft: "10px" }}>
              Show Errors Only
            </Form.Check.Label>
          </Form.Check>
        </Col>
        <Col md={6} className="d-flex justify-content-end">
          <Form.Control
            type="text"
            placeholder="Search..."
            aria-label="Search"
            className="mb-2"
            style={{
              maxWidth: "300px",
              border: "1px solid black",
              padding: "5px",
              borderRadius: "5px",
            }}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentPage(0);
            }}
          />
        </Col>
      </Row>

      <div className="table-responsive previewtable">
        <Table bordered hover responsive>
          <thead className="table-dark">
            <tr style={{ textAlign: "center" }}>
              <th style={{ textAlign: "center" }}>Sl.No</th>
              {headers.map((header, idx) => (
                <th key={idx} style={{ textAlign: "center" }}>
                  {header}
                </th>
              ))}
              <th>Errors</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.length > 0 ? (
              currentItems.map((row, idx) => (
                <tr key={idx}>
                  <td className="text-center">{getSerialNumber(idx)}</td>
                  <td className="text-center">{row.CUSTNUMBER}</td>
                  <td className="text-left">{row.ACTTYPE}</td>
                  <td className="text-left">{row.CUSTOMERNAME}</td>
                  <td className="text-left" style={{ color: "red" }}>
                    {row.errorText}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={headers.length + 1} className="text-center">
                  No data found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {pageCount > 1 && (
        <Col className="d-flex justify-content-end">
          <ReactPaginate
            previousLabel={"«"}
            nextLabel={"»"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            activeClassName={"active"}
            forcePage={currentPage}
          />
        </Col>
      )}
    </Row>
  );
};

export default CsvPreview;
