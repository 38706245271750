import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';

export const customTheme=createTheme(
    {
      palette:{
        primary:{
          main:'#2E3B55'
        },
        secondary:orange
      },
      typography: {
        fontFamily: 'Quicksand',
        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightMedium: 600,
        fontWeightBold: 700,
      }
    }
  );

  // export const customTheme = createTheme({
  //   palette: {
  //     primary: {
  //       main: '#1976d2', // Sky Blue 
  //       light: '#63a4ff', 
  //       dark: '#004ba0', 
  //     },
  //     secondary: {
  //       main: '#ff9800', // Bright Orange
  //       light: '#ffc947',
  //       dark: '#c66900',
  //     },
  //     background: {
  //       default: '#f5f5f5', // Light Grey
  //       paper: '#ffffff',   // White for cards or panels
  //     },
  //     text: {
  //       primary: '#212121', // Dark grey 
  //       secondary: '#757575', // Medium grey for subtitles
  //     },
  //   },
  //   typography: {
  //     fontFamily: 'Roboto, Montserrat, sans-serif', 
  //     h1: {
  //       fontFamily: 'Montserrat, sans-serif',
  //       fontWeight: 700,
  //       fontSize: '3rem',
  //     },
  //     h2: {
  //       fontFamily: 'Montserrat, sans-serif',
  //       fontWeight: 600,
  //       fontSize: '2rem',
  //     },
  //     body1: {
  //       fontFamily: 'Roboto, sans-serif',
  //       fontSize: '1rem',
  //     },
  //     button: {
  //       fontFamily: 'Roboto, sans-serif',
  //       textTransform: 'uppercase',
  //       fontWeight: 500,
  //     },
  //   },
  // });
