import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Drawer, Typography, Toolbar,ListItem,ListItemText } from "@mui/material";
import { useNavigate, useLocation, Outlet, Link } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
// import TreeView from "@mui/lab/TreeView";
// import TreeItem from "@mui/lab/TreeItem";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CssBaseline from "@mui/material/CssBaseline";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
// import useIdle from "../services/common/handle-idle-timeout";
import Popup from "./popup";
import AgentContingency from "../components/agent-contingency";
import CustomTreeView from "./CustomTreeView";
// import { Nav ,Offcanvas} from "react-bootstrap";

import {
  getUserDetailsFromToken,
  getIdKey,
  getStateKey,
} from "../services/auth/utils";
import authClient from "../services/auth/access-api";
import config from "../services/auth/config";
import coreAPI from "../services/api/core-api.service";
import LoadingPage from "../components/error-handling/loading.component";
import AccessDenied from "../components/error-handling/accessDenied.component";
import ServerError from "../components/error-handling/serverError.component";
import BroadcastMessage from "../components/broadcast-message";

/**
 * *************************************************************************************************************************
 * @adminLayout layout Component
 * @description Admin component to manage Treeview and sidebar for Elastic Admin UI
 * *************************************************************************************************************************
 */

/**
 * *************************************************************************************************************************
 * @designLayer of layout Start
 * @description list of styled and themes components
 * *************************************************************************************************************************
 */
const drawerWidth = 350;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  background: "#f9f9f9",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const useStyles = makeStyles((theme) => {
  return {
    page: {
      background: "#f9f9f9",
      width: "100%",
      padding: theme.spacing(3),
    },
    root: {
      display: "flex",
      width: "100%",
    },
    drawer: {
      width: drawerWidth,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    active: {
      background: "#f4f4f4",
    },
    title: {
      padding: theme.spacing(2),
    },
    align: {
      float: "right",
    },
    appBar: {
      width: "calc(100% - ${drawerWidth}px)",
      marginLeft: drawerWidth,
      //background: '#fefefe',
      background: "#2E3B55",
      padding: theme.spacing(0),
    },
    date: {
      flexGrow: 1,
    },
    toolbar: theme.mixins.toolbar,
  };
});

/**
 * *************************************************************************************************************************
 * @designLayer of layout End
 * *************************************************************************************************************************
 */

export default function Layout({ children, signOut }) {
  /**
   * *************************************************************************************************************************
   * @dataLayer of layout Start
   * @description list of constants and variable assignment
   * *************************************************************************************************************************
   */

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [auth, setAuth] = React.useState(true);
  const [loginUserID, setLoginUserID] = React.useState(null);
  const [userFullName, setUserFullName] = React.useState(null);
  const [userLobTag, setUserLobTag] = React.useState(null);
  const [userAccessControl, setUserAccessControl] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [drawerWidth, setDrawerWidth] = React.useState(null);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openBroadCastPopup, setOpenBroadCastPopup] = React.useState(false);

  const [objTreeViewData, setObjTreeViewData] = useState([]);
  var v_objTreeViewDataList = null;
  var reqTag = "NA";
  /**
   * *************************************************************************************************************************
   * @dataLayer of layout End
   * *************************************************************************************************************************
   */

  /**
   * *************************************************************************************************************************
   * @serviceLayer of layout Start
   * @description list of API Services - Retrive treeView and userProfile
   * @serviceList 1. /userprofiles/{user_id}=>Retrieve User Profile from Connect based on userID
   *              2. /configuration/tree=> Retrive treeView List based on tags.
   *              3. handle IdleTimeout and DrawerEvents
   * *************************************************************************************************************************
   */

  /**
   * *************************************************************************************************************************
   * @userprofiles  Service Start
   * *************************************************************************************************************************
   */

  useEffect(() => {
    (async () => {
      // console.log("Get UserProfile(): Start");
      if (sessionStorage.getItem("isLoggedIn") === "true") {
        const userId = getUserDetailsFromToken(
          "Employee Id",
          sessionStorage.getItem("ACCESS_TOKEN")
        );
        setLoginUserID(userId);
        localStorage.setItem("v_userId", userId);
        const userProfileURL = `/userprofiles/${userId}`;
        try {
          const resUserProfile = await coreAPI().get(userProfileURL);

          if (resUserProfile.data != undefined) {
            localStorage.setItem(
              "userProfile",
              JSON.stringify(resUserProfile.data)
            );

            setUserFullName(
              resUserProfile.data.firstName + " " + resUserProfile.data.lastName
            );
            setUserLobTag(resUserProfile.data.userLobTag);
            setUserAccessControl(resUserProfile.data.accessControl);
            localStorage.setItem(
              "v_userLobTag",
              resUserProfile.data.userLobTag
            );
            localStorage.setItem(
              "v_accessControl",
              resUserProfile.data.accessControl
            );

            getConfigTree();
          }
        } catch (err) {
          console.log("Error Occured while getting userProfile:", err);
          handleAccessDenied();
        }
      }
    })();
  }, []);

  const handleSignOff = () => {
    localStorage.clear();
    sessionStorage.clear();

    authClient.signOff(getIdKey(), getStateKey());
  };

  const handleAccessDenied = () => {
    console.log("handleAccessDenied():start");
    navigate("/denied");
  };

  /**
   * *************************************************************************************************************************
   * @userprofiles  Service End
   * *************************************************************************************************************************
   */

  /**
   * *************************************************************************************************************************
   * @treeview treeview Service Start
   * *************************************************************************************************************************
   */

  //const  getConfigTree = () => {

  const getConfigTree = () => {
    (async () => {
      //useEffect(() => { (async () => {

      var v_userLobTag = localStorage.getItem("v_userLobTag");
      var v_accessControl = localStorage.getItem("v_accessControl");
      if (v_accessControl === "Admin") {
        reqTag = "all";
      } else if (
        v_accessControl.includes("AdminLite") ||
        v_accessControl.includes("Supervisor")
      ) {
        reqTag = v_userLobTag;
      } else {
        handleAccessDenied();
      }

      var reqBody = {
        tags: [reqTag],
        parentTag: "global",
      };
      console.log("getConfigTree():reqBody:", { reqBody });
      //const resObjTreeViewData = await coreAPI().get('/configuration/tree');
      const resObjTreeViewData = await coreAPI().post(
        "/configuration/tree",
        reqBody
      );
      // console.log("configuration/tree(): Start");
      console.log("getConfigTree():resObjTreeViewData:", {
        resObjTreeViewData,
      });
      //localStorage.setItem("resObjTreeViewData", JSON.stringify(resObjTreeViewData.data[0].children[0].children));
      localStorage.setItem(
        "resObjTreeViewData",
        JSON.stringify(resObjTreeViewData.data)
      );
      setObjTreeViewData([...objTreeViewData, ...resObjTreeViewData.data]);
      v_objTreeViewDataList = JSON.parse(
        localStorage.getItem("resObjTreeViewData")
      );
      setLoading(false);
      setOpen(true);
      setDrawerWidth(350);
      handleHomeScreen();

      // Default Path
      // console.log("Layout() resObjTreeViewData: "+JSON.stringify(resObjTreeViewData.data));
    })();
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    setDrawerWidth(350);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setDrawerWidth(20);
  };

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHomeScreen = () => {
    let defaultTreeId = "objectConfig/configSettings/dnis";
    return <Link to={defaultTreeId}></Link>;
  };

  // const getNodeIdsToExpand = (treeItems) => {
  //   let nodeIdsToExpand = [];

  //   if (treeItems && treeItems[0]) {
  //     //Always expand the first node if the tree is loaded
  //     nodeIdsToExpand.push(treeItems[0].id);
  //   } else {
  //     return [];
  //   }

  //   //Any selected objectId would have been saved in local storage by now. We will use it to determine nodeIds to expand
  //   try {
  //     var objectId = JSON.parse(localStorage.getItem("objectId"));
  //   } catch (err) {
  //     console.log("getNodeIdsToExpand(): Error: ", err.message);
  //   }

  //   if (!objectId || objectId === "/") {
  //     //No objectId was selected
  //     return nodeIdsToExpand;
  //   }

  //   const nodesToExpand = objectId.split("/");
  //   let previousNode = "";
  //   let treeItem = treeItems[0];
  //   for (var nodeToExpand of nodesToExpand) {
  //     if (nodeToExpand) {
  //       previousNode += "/" + nodeToExpand;

  //       for (var treeItemChild of treeItem.children) {
  //         if (treeItemChild.id === previousNode) {
  //           treeItem = treeItemChild;
  //           if (treeItem.children) {
  //             nodeIdsToExpand.push(previousNode);
  //           }
  //           break;
  //         }
  //       }
  //     }
  //   }

  //   return nodeIdsToExpand;
  // };

  // const getTreeItemsFromData = (treeItems) => {
  //   return treeItems.map((treeItemData) => {
  //     let children = undefined;
  //     if (treeItemData.children && treeItemData.children.length > 0) {
  //       children = getTreeItemsFromData(treeItemData.children);
  //     }

  //     return (
  //       //<Link to={`objectConfig${treeItemData.id}`} style={{ textDecoration: 'none' }}>
  //       <Link to={`objectConfig${treeItemData.id}`}>
  //         <TreeItem
  //           key={treeItemData.id}
  //           nodeId={treeItemData.id}
  //           label={treeItemData.name}
  //           children={children}
  //           onClick={() => updateNodeInLocalStorage(treeItemData.id)}
  //         />
  //       </Link>
  //     );
  //   });
  // };

  const DataTreeView = ({ treeItems }) => {
    return (
      // <TreeView
      //   defaultCollapseIcon={<ExpandMoreIcon />}
      //   defaultExpandIcon={<ChevronRightIcon />}
      //   defaultExpanded={getNodeIdsToExpand(treeItems)}
      // >
      //   {getTreeItemsFromData(treeItems)}
      // </TreeView>
      
      <CustomTreeView treeItems={treeItems} handleClick={(id) => {
        console.log("Clicked node:", id);
        updateNodeInLocalStorage(id);
      }}/>
    );
  };

  //This is a function that protects against wrong configurations (as seen during development, sometimes the tree item id is not a valid branch..)
  const verifyNodeIsValidBranch = (treeItem, nodeToVerify) => {
    if (treeItem.id === nodeToVerify && treeItem.children) {
      return true;
    }

    for (var treeItemChild of treeItem.children) {
      if (treeItemChild.children) {
        if (verifyNodeIsValidBranch(treeItemChild, nodeToVerify)) {
          return true;
        }
      }
    }
    return false;
  };

  const updateNodeInLocalStorage = (v_path) => {
    console.log("updateNodeInLocalStorage(): Start");
    console.log("updateNodeInLocalStorage(): v_path", v_path);

    var objectId = JSON.parse(localStorage.getItem("objectId"));
    console.log("updateNodeInLocalStorage(): objectId", objectId);
    if (objectId && objectId === v_path) {
      console.log("updateNodeInLocalStorage(): Path Matched");
      var v_accessControl = localStorage.getItem("v_accessControl");
      localStorage.setItem("objectId", JSON.stringify(v_path));

      //The user clicked the node that was previously expanded.
      //We need to set the previous node in local storage so the current node can be unselected upon refresh
      const posLastSlash = v_path.lastIndexOf("/");
      let parent_v_path = v_path.substring(0, posLastSlash);
      if (!parent_v_path) {
        parent_v_path = "/";
      }
      localStorage.setItem("objectId", JSON.stringify(parent_v_path));
    } else if (verifyNodeIsValidBranch(objTreeViewData[0], v_path)) {
      localStorage.setItem("objectId", JSON.stringify(v_path));
    }

    // console.log("updateNodeInLocalStorage(): End");
  };

  /**
   * *************************************************************************************************************************
   * @treeview treeview Service End
   * *************************************************************************************************************************
   */

  /**
   * *************************************************************************************************************************
   * @hanleIdleTimeout Services Start
   * *************************************************************************************************************************
   */

  const handleIdle = () => {
    // console.log("HandleIdleTimeout():Idle");
  };
  //console.log("HandleIdleTimeout():config.IDLE_TIMEOUT:"+config.IDLE_TIMEOUT);
  // const { idleTimer } = useIdle({
  //   onIdle: handleIdle,
  //   idleTime: Number(config.IDLE_TIMEOUT),
  // });

  // const stay = () => {
  //   // console.log("HandleIdleTimeout():stay");
  //   idleTimer.reset();
  // };

  const handleLogout = () => {
    handleSignOff();
  };

  const handleAudit = () => {
    navigate("/audit");
  };
  /**
   * *************************************************************************************************************************
   * @hanleIdleTimeout Services End
   * *************************************************************************************************************************
   */

  /**
   * *************************************************************************************************************************
   * @serviceLayer of layout End
   * @description list of API Services - Retrive treeView and userProfile
   * *************************************************************************************************************************
   */

  /**
   * *************************************************************************************************************************
   * @uiLayer of layout Start
   * @description Display treeView and UserProfile.
   * *************************************************************************************************************************
   *
  */

  return (
    <>
      {loading === false ? (
        <div className={classes.root}>
          <Box sx={{ display: "flex", width: "100%" }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{ mr: 2, ...(open && { display: "none" }) }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Connect Elastic Admin (EA)
                </Typography>
                <Button color="secondary" onClick={() => handleAudit()}>
                  {" "}
                  Audit Summary
                </Button>
                <Button color="secondary" onClick={() => setOpenPopup(true)}>
                  {" "}
                  Agent Contingency
                </Button>
                <Button color="secondary" onClick={() => setOpenBroadCastPopup(true)}>
                  {" "}
                  Broadcast Messsage
                </Button>
                <div>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>
                      Name:{userFullName}
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      Business Unit:{userLobTag}
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      Access Control:{userAccessControl}
                    </MenuItem>
                    <MenuItem onClick={handleClose}>Version:1.0</MenuItem>
                    <MenuItem onClick={handleClose}>
                      {" "}
                      <Button color="secondary" onClick={() => handleSignOff()}>
                        {" "}
                        Logout
                      </Button>
                    </MenuItem>
                  </Menu>
                </div>
              </Toolbar>
            </AppBar>

            {/* side drawer */}
            <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  boxSizing: "border-box",
                  // padding:"16px"
                },
              }}
              variant="persistent"
              anchor="left"
              open={open}
            >
              <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "ltr" ? (
                    <ChevronLeftIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </IconButton>
              </DrawerHeader>
              <Divider />

             
              <DataTreeView treeItems={objTreeViewData} />
              
              {
                 (userAccessControl === "Admin" || userAccessControl === "CCOAdminLite") && (
                  // <TreeView>
                  //   <Link to={`iataUploader`}>
                  //     <TreeItem
                      
                  //       label={"IATA File Upload"}
                  //       onClick={() => null}
                  //     />
                  //   </Link>
                  //  </TreeView>
                 
                  <ListItem className={classes.listItem} onClick={()=>navigate("/iataUploader")} style={{
                    paddingLeft : 15,
                    cursor: "pointer",
                  }}>
                    
                    <ListItemText
                      primary={
                        <span
                          style={{
                            textDecoration: "underline",
                            color: "#0d6efd",
                            display: "block",
                          }}
                        >
                          IATA File Upload
                        </span>
                      }
                    />
                  </ListItem>
                  )
              }
               
            </Drawer>

            {/* main content */}
            <div className={classes.page}>
              <div className={classes.toolbar}></div>
              {children}
              <Outlet />
            </div>

            <div>
              <Popup
                title="Agent Contingency"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
              >
                <AgentContingency />
              </Popup>
            </div>
            <div>
              <Popup
                title="Broadcast Message"
                openPopup={openBroadCastPopup}
                setOpenPopup={setOpenBroadCastPopup}
              >
                <BroadcastMessage setOpenPopup={setOpenBroadCastPopup}/>
              </Popup>
            </div>
          </Box>
        </div>
      ) : (
        <LoadingPage />
      )}
    </>
  );
}

/**
 * *************************************************************************************************************************
 * @uiLayer of layout End
 * *************************************************************************************************************************
 */
