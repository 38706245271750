import React, { useEffect, useRef, useState, useCallback } from "react";
import { List, ListItem, ListItemText, Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function CustomTreeView({ treeItems, handleClick }) {
  const [open, setOpen] = useState(() => {
    const savedState = localStorage.getItem("treeOpenState");
    if (savedState) {
      return JSON.parse(savedState);
    }

    // set the root node to open by default when the page is loaded / opened
    const initialState = {};
    if (treeItems && treeItems.length > 0) {
      initialState[treeItems[0].id] = true; 
    }
    return initialState;
  });

  const listRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("treeOpenState", JSON.stringify(open));
  }, [open]);

  const toggleOpen = (id) => {
    setOpen((prev) => {
      const newState = { ...prev, [id]: !prev[id] };
      localStorage.setItem("treeOpenState", JSON.stringify(newState));
      return newState;
    });
  };

  const handleScrollToItem = useCallback((id) => {
    if (listRef.current) {
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 100);
    }
  }, []);

  const renderTree = (items, level = 1) => {
    const paddingLeft = `${level * 15}px`;

    return items.map((item) => (
      <React.Fragment key={item.id}>
        <ListItem
          component="div"
          id={item.id}
          onClick={(e) => {
            toggleOpen(item.id);
            handleClick(item.id);
            localStorage.setItem("selectedNode", JSON.stringify(item.id));
            handleScrollToItem(item.id);
            navigate(`objectConfig${item.id}`);
            e.stopPropagation();
          }}
          style={{
            paddingLeft,
            cursor: "pointer",
            marginBottom: -20,
          }}
        >
          <ListItemText
            primary={
              <span
                onClick={(e) => {
                  toggleOpen(item.id);
                  handleClick(item.id);
                  localStorage.setItem("selectedNode", JSON.stringify(item.id));
                  handleScrollToItem(item.id);
                  navigate(`objectConfig${item.id}`);
                  e.stopPropagation();
                }}
              >
                {item.name}
              </span>
            }
            primaryTypographyProps={{
              style: {
                textDecoration: "underline",
                color: "#0d6efd",
              },
            }}
          />
          {item.children && (open[item.id] ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        {item.children && (
          <Collapse
            in={open[item.id]}
            timeout="auto"
            style={{
              transition: "height 300ms ease-in-out",
            }}
            unmountOnExit
          >
            <List component="div" disablePadding>
              {renderTree(item.children, level + 1)}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    ));
  };

  return (
    <div ref={listRef}>
      <List>{renderTree(treeItems)}</List>
    </div>
  );
}

export default CustomTreeView;

